import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Button, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import { BsHeadset, BsPersonCircle } from "react-icons/bs";
import { HiOutlineTicket } from "react-icons/hi";
import { MdMail, MdPhone } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import DesktopLogo from "../images/Toot-Hill-Master-Logo-Reversed-new.svg";
import MobileLogo from "../images/Toot-Hill-Master-Logo-Reversed-new.svg";
import { TiWeatherPartlySunny } from "react-icons/ti";
import parse from "html-react-parser";

const Navigation = () => {
	const data = useStaticQuery(graphql`
		query {
			wpBanner {
				bannerFields {
					bannerText
					showOnWebsite
				}
			}
		}
	`);

	return (
		<>
			<Navbar bg="primary" expand="xl">
				<Container
					style={{ zIndex: 2 }}
					className="position-relative text-end text-xl-start d-flex justify-content-between"
				>
					<Navbar.Brand as={Link} to="/">
						<img
							src={MobileLogo}
							alt="Toot Hill Golf Logo"
							style={{
								width: "120px",
							}}
						/>
					</Navbar.Brand>
					<div className="menu-button">
						<Button
							variant="white"
							className="fs-6"
							href="https://bookings.toothillgolfclub.co.uk?utm_source=Google&utm_medium=Search+Ad&utm_campaign=Golfing+for+Individuals+%7C+Essex+%7C+Search&utm_id=21850559424"
							target="_blank"
							rel="noreferrer"
							style={{ padding: "10px 24px" }}
						>
							Reserve tee time
						</Button>
					</div>
				</Container>
			</Navbar>
			{/* {data.wpBanner.bannerFields.showOnWebsite && (
        <div className=" py-2  bg-secondary ">
          <Container style={{ zIndex: 2 }} className="position-relative">
            <Row>
              <Col className="text-center ">
                <p
                  style={{ fontSize: "1.3rem" }}
                  className="text-primary pb-0 mb-0 fs-7 ssp-bold"
                >
                  {parse(data.wpBanner.bannerFields.bannerText)}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      )} */}
		</>
	);
};

export default Navigation;
