import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/googleAdsLayout";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { Helmet } from "react-helmet";
import HomeHero from "../components/heroGoogleAds";
import { checkPropertiesForNull, groupArrayInPairs } from "../utils/object";
import Reviews from "../components/reviews";
import { Button } from "react-bootstrap";
import BumperBanner from "../components/numbersSection";
import uuid4 from "uuid4";
import ImageCarousel from "../components/changingImage";
import ContactForm from "../components/contactForm";
import ReviewSlider from "../components/reviewSection";
import CtaBanner from "../components/cta-banner";
import GetInTouch from "../components/get-in-touch";

const GoogleAdsPostTemplate = ({ data }) => {
  const {
    post: {
      slug,
      title,
      seoFieldGroups,
      googleAdsPostFieldGroups: { googleAdsPostFields },
    },
    site,
    schemaLogo,
  } = data;

  const siteUrl = site.siteMetadata.siteUrl;

  const schemaLogoUrl = schemaLogo?.publicURL;

  const [currentSlides, setCurrentSlides] = useState([0, 0, 0, 0, 0, 0, 0]);

  const updateSlides = (index) => {
    setCurrentSlides((prevSlides) => {
      const updatedSlides = [...prevSlides];
      updatedSlides[index] = updatedSlides[index] === 1 ? 0 : 1;
      return updatedSlides;
    });

    setTimeout(() => {
      const nextIndex = (index + 1) % currentSlides.length;
      updateSlides(nextIndex);
    }, 1500);
  };

  useEffect(() => {
    const galleryData = googleAdsPostFields.filter(
      (item) =>
        item.fieldGroupName ===
        "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_GallerySection"
    );

    if (galleryData.length > 0 && galleryData[0]?.imageGallery?.length > 0) {
      updateSlides(0);
    }
  }, [googleAdsPostFields]);

  const galleryData = googleAdsPostFields.filter(
    (item) =>
      item.fieldGroupName ===
      "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_GallerySection"
  );
  let groupedImages = [];

  if (galleryData.length > 0) {
    groupedImages = groupArrayInPairs(galleryData[0].imageGallery);
  }

  const gallery1 = groupedImages.slice(0, 3);
  const gallery2 = groupedImages.slice(3, 5);
  const gallery3 = groupedImages.slice(5, 7);

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `${siteUrl}/${slug}`,
    image: `${siteUrl}${seoFieldGroups?.image?.publicURL}`,
    mpn: `${siteUrl}/${slug}`,
    sku: `${siteUrl}/${slug}`,
    description: `${seoFieldGroups?.productSchema}`,
    logo: `${siteUrl}${schemaLogoUrl}`,
    name: "Seymour Locksmiths",
    url: `${siteUrl}`,
    brand: {
      "@type": "Organization",
      logo: `${siteUrl}${schemaLogoUrl}`,
      image: `${siteUrl}${seoFieldGroups?.image?.publicURL}`,
      name: "Seymour Locksmiths",
      url: `${siteUrl}`,
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Toot Hill Golf Club",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        name: `Toot Hill Golf Club ${title}`,
        item: {
          url: `${siteUrl}/${slug}`,
          id: `${siteUrl}/${slug}`,
        },
      },
    ],
  };

  return (
    <>
      {/* <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
        {seoFieldGroups?.metaKeywords && (
          <meta name="keywords" content={seoFieldGroups?.metaKeywords} />
        )}
       
      </Helmet> */}
      {/* <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet> */}
      <GatsbySeo
      nofollow
				noindex
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
       
        
      />

      <Layout>
        {googleAdsPostFields.length > 0 &&
          googleAdsPostFields.map((sectionInfo) => (
            <>
              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_BannerSection" && (
                <HomeHero
                page={title}
                  title={sectionInfo?.heading}
                  subHeading={sectionInfo?.subHeading}
                  content={sectionInfo?.description}
                  button={sectionInfo?.cta1}
                  button2={sectionInfo?.cta2}
                  video={sectionInfo?.video.mediaItemUrl}
                  showForm={sectionInfo?.showForm}
                  reviewCount={data.reviews.reviewCount}
                />
              )}

              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_ReviewsSection" && (
                <>
                  {sectionInfo?.enableSlider ? (
                    <ReviewSlider
                      heading={sectionInfo?.heading}
                      subHeading={sectionInfo?.subHeading}
                      reviews={data.reviews.reviews}
                      cta={sectionInfo?.cta}
                    />
                  ) : (
                    <section className="py-4 py-md-5 py-lg-7">
                      <Container>
                        <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                          <Col lg={8}>
                            {sectionInfo?.subHeading && (
                              <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                                {sectionInfo?.subHeading}
                              </p>
                            )}

                            <h2 className="line-heading-dark text-center mb-5 fs-1 merriweather-regular">
                              {sectionInfo?.heading}
                            </h2>
                          </Col>
                        </Row>
                        <Reviews reviewsData={data.reviews.reviews} cardRadius={true}/>
                        {sectionInfo.cta && sectionInfo.cta?.url && (
                          <Col>
                            <div className="text-center">
                              <Button
                                variant="outline-white"
                                as={Link}
                                target={sectionInfo.cta?.target ?? "_blank"}
                                rel="noreferrer"
                                href={sectionInfo.cta?.url}
                                className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-5 px-6 "
                              >
                                {sectionInfo.cta?.title}
                              </Button>
                            </div>
                          </Col>
                        )}
                      </Container>
                    </section>
                  )}
                </>
              )}
              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_WelcomeSection" && (
                <section className="py-4 py-md-5 py-lg-7">
                  <Container>
                    <Row className="justify-content-center">
                      <Col className="text-center" lg={8}>
                        <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                          {sectionInfo?.subHeading}
                        </p>
                        <h2 className="line-heading-dark text-center mb-4 fs-1 merriweather-regular">
                          {sectionInfo?.heading}
                        </h2>
                        {sectionInfo?.description && (
                          <div className="text-center">
                            <SafeHtmlParser
                              htmlContent={sectionInfo?.description}
                            />
                          </div>
                        )}
                        {sectionInfo.cta && sectionInfo.cta.url && (
                          <Button
                            variant="primary"
                            href={sectionInfo.cta.url}
                            target={sectionInfo.cta.target ?? "_blank"}
                            rel="noreferrer"
                            className="py-3 w-100 w-md-auto mb-4 mb-md-0 mt-4 px-5 "
                          >
                            {sectionInfo.cta.title}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}

              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_TwoColumnSection1" && (
                <section className="py-4 py-md-5 py-lg-7 bg-primary">
                  <Container>
                    <Row className="justify-content-center mb-6">
                      <Col lg={9} className="text-center">
                        <h2 className="  text-white mb-3 display-6 merriweather-regular">
                          {sectionInfo?.heading}
                        </h2>
                      </Col>
                    </Row>
                    <Row className="g-5 gx-lg-7 align-items-center">
                      <Col lg={6}>
                        <GatsbyImage
                          className="w-100 rounded-2"
                          image={
                            sectionInfo?.image?.localFile?.childImageSharp
                              .gatsbyImageData
                          }
                          alt={sectionInfo.image.altText}
                        />
                      </Col>
                      <Col lg={6}>
                        {sectionInfo?.description && (
                          <div className="text-white">
                            <SafeHtmlParser
                              htmlContent={sectionInfo?.description}
                            />
                          </div>
                        )}
                        {sectionInfo?.cta && (
                          <Button
                            variant="white"
                            as={Link}
                            href={sectionInfo?.cta.url}
                            className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-3 px-5"
                            target={sectionInfo?.cta.target ?? "_blank"}
                          >
                            {sectionInfo?.cta.title}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}

              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_GallerySection" && (
                <section className="py-4 py-md-5 py-lg-7">
                  <Container>
                    <Row>
                      <Col>
                        <h2 className="text-primary mb-4 fs-1 merriweather-regular">
                          {sectionInfo?.heading}
                        </h2>
                        {sectionInfo.description && (
                          <div className="mb-4">
                            <SafeHtmlParser
                              htmlContent={sectionInfo?.description}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>

                    {sectionInfo?.imageGallery &&
                      sectionInfo.imageGallery.length > 0 && (
                        <>
                          <Row className="d-none d-md-flex">
                            <Col md={6} lg={4} className="g-3">
                              {gallery1.map((pair, i) => (
                                <ImageCarousel
                                  images={pair}
                                  height="310px"
                                  activeslide={currentSlides[i]}
                                  key={i}
                                  borderRadius={true}
                                />
                              ))}
                            </Col>
                            <Col md={6} lg={4} className="g-3">
                              {gallery2.map((pair, i) => (
                                <ImageCarousel
                                  images={pair}
                                  height={i === 0 ? "310px" : "636px"}
                                  activeslide={currentSlides[i + 3]}
                                  key={i}
                                  borderRadius={true}
                                />
                              ))}
                            </Col>
                            <Col lg={4} className="g-3 d-none d-lg-block">
                              {gallery3.map((pair, i) => (
                                <ImageCarousel
                                  images={pair}
                                  height={i === 0 ? "636px" : "310px"}
                                  activeslide={currentSlides[i + 5]}
                                  key={i}
                                  borderRadius={true}
                                />
                              ))}
                            </Col>
                          </Row>
                          <div className="d-md-none d-grid gallery-wrapper gap-3">
                            {sectionInfo.imageGallery
                              .slice(0, 6)
                              .map((item, i) => (
                                <div className="gallery-item" key={i}>
                                  <GatsbyImage
                                    className="w-100 mb-3 d-lg-none"
                                    style={{ height: "100%", borderRadius: "6px"  }}
                                    image={
                                      item.localFile.childImageSharp
                                        .gatsbyImageData
                                    }
                                    alt={item.altText}
                                    imgStyle={{ objectPosition: "right center", borderRadius: "6px" }}
                                  />
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                  </Container>
                </section>
              )}
              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_AboutSection" && (
                <section className="py-5 bg-primary py-lg-7">
                  <Container>
                    <Row className="g-5 gx-lg-7">
                      <Col lg={5}>
                        {sectionInfo?.subHeading && (
                          <p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
                            {sectionInfo?.subHeading}
                          </p>
                        )}

                        <h2 className=" text-white mb-3 merriweather-regular">
                          {sectionInfo?.heading}
                        </h2>
                        {sectionInfo.description && (
                          <div className="text-white">
                            <SafeHtmlParser
                              htmlContent={sectionInfo.description}
                            />
                          </div>
                        )}

                        <Button
                          variant="white"
                          as={Link}
                          href={sectionInfo?.cta?.url}
                          className="py-3 w-100 w-md-auto mb-4 mb-md-0 mt-3 px-5"
                          target="_blank"
                        >
                          {sectionInfo?.cta?.title}
                        </Button>
                      </Col>
                      {sectionInfo.stats && sectionInfo.stats.length > 0 && (
                        <Col lg={7}>
                          <Row className="g-0">
                            {sectionInfo.stats.map((item, i) => (
                              <Col
                                className="text-center"
                                style={{
                                  borderRight:
                                    (i + 1) % 2 !== 0 ? "1px solid white" : "",
                                }}
                                xs={6}
                                key={uuid4()}
                              >
                                <p className="display-5 pb-0 mb-0 text-white merriweather-light">
                                  {item.count}
                                </p>
                                <p className="fs-2 text-white pb-0 mb-0 merriweather-light">
                                  {item.heading}
                                </p>

                                {i < 2 && (
                                  <div
                                    className="my-4 w-100 bg-white"
                                    style={{ height: "1px" }}
                                  ></div>
                                )}
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Container>
                </section>
              )}
              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_PricingSection" && (
                <section className="py-5 bg-white py-lg-7">
                  <Container>
                    <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                      <Col lg={8}>
                        {sectionInfo?.subHeading && (
                          <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                            {sectionInfo?.subHeading}
                          </p>
                        )}

                        <h2 className="line-heading-dark text-center mb-5 mb-lg-7 fs-1 merriweather-regular">
                          {sectionInfo?.heading}
                        </h2>
                      </Col>
                    </Row>
                    {sectionInfo.price && sectionInfo?.price.length > 0 && (
                      <Row>
                        <Col>
                          <Row className="g-5">
                            {sectionInfo?.price.map((priceItem) => (
                              <Col
                                className="text-center "
                                style={{ minHeight: "100%" }}
                                xl={4}
                                key={uuid4()}
                              >
                                <div className="  text-center  ">
                                  <h3 className="text-uppercase text-dark-green fs-5 pb-3 merriweather-regular">
                                    {priceItem.heading}
                                  </h3>
                                </div>
                                <div
                                  style={{ height: "1px" }}
                                  className="bg-dark-green mx-auto mb-3 w-60"
                                ></div>
                                {priceItem.count &&
                                  priceItem.count.length > 0 && (
                                    <>
                                      {priceItem.count.map((item) => (
                                        <div key={uuid4()}>
                                          <h4 className="ssp-regular fs-6 text-dark-green">
                                            {item.heading}
                                          </h4>
                                          <p>{item.price}</p>
                                        </div>
                                      ))}
                                    </>
                                  )}
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {sectionInfo.cta && sectionInfo.cta.url && (
                      <Row>
                        <Col className="text-center mt-5 mt-lg-7">
                          <Button
                            style={{ borderRadius: "0px" }}
                            variant="primary"
                            href={sectionInfo.cta?.url}
                            target={sectionInfo.cta?.target ?? "_blank"}
                            rel="noreferrer"
                            className="py-3 w-100 text-white w-md-auto mb-4 mb-md-0 px-5 "
                          >
                            {sectionInfo.cta?.title}
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Container>
                </section>
              )}

              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_CtaSection" && (
                <CtaBanner
                  heading={sectionInfo?.heading}
                  description={sectionInfo?.description}
                  backgroundImage={
                    sectionInfo?.backgroundImage?.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  button1Text={sectionInfo?.cta1?.title}
                  button1Link={sectionInfo?.cta1?.url}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_GoogleMapSection" && (
                <section className="py-4 py-md-5 py-lg-7">
                  <Container>
                    <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                      <Col lg={8}>
                        {sectionInfo.subheading && (
                          <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                            {sectionInfo.subheading}
                          </p>
                        )}

                        <h2 className="line-heading-dark text-center text-dark-green mb-5 fs-1 merriweather-regular">
                          {sectionInfo.heading}
                        </h2>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col lg={8}>
                        <p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
                          Address
                        </p>

                        <h2 className="  text-dark-green mb-5 fs-4 merriweather-regular">
                          {sectionInfo?.address}
                        </h2>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <iframe
                          className="position-relative google-map rounded-2"
                          src={sectionInfo?.map}
                          width="100%"
                          style="border:0;"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                        {sectionInfo.cta && sectionInfo.cta.url && (
                          <div className="text-center">
                            <Button
                              style={{ borderRadius: "0px" }}
                              variant="outline-primary"
                              target={sectionInfo.cta?.target ?? "_blank"}
                              rel="noreferrer"
                              href={sectionInfo.cta?.url}
                              className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-5 px-6 "
                            >
                              {sectionInfo.cta?.title}
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
              {sectionInfo.fieldGroupName ===
                "GoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_GetInTouch" && (
                <GetInTouch
                  heading={sectionInfo?.heading}
                  subHeading={sectionInfo?.subheading}
                  backgroundImage={
                    sectionInfo?.image?.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  formButtonLabel={sectionInfo?.formBtnLabel}
                />
              )}
            </>
          ))}
      </Layout>
    </>
  );
};

export default GoogleAdsPostTemplate;

export const pageQuery = graphql`
  query GoogleAdsPostById($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    reviews {
      reviewCount
      reviews {
        author
        datePublished
        source
        reviewBody
      }
    }
    post: wpGoogleAdsPage(id: { eq: $id }) {
      slug
      title
      seoFieldGroups {
        localBusinessSchema
        metaDescription
        metaTitle
        openGraphDescription
        openGraphTitle
        productSchema
        image {
          altText
          publicUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP]
                quality: 100
                transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                layout: CONSTRAINED
                placeholder: BLURRED
              )
              original {
                width
                height
              }
            }
          }
        }
      }
      googleAdsPostFieldGroups {
        googleAdsPostFields {
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_BannerSection {
            fieldGroupName
            description
            showForm
            heading
            subHeading
            cta1 {
              target
              title
              url
            }
            video {
              altText
              mediaItemUrl
            }
          }
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_ReviewsSection {
            fieldGroupName
            heading
            subHeading
            enableSlider
          }
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_WelcomeSection {
            description
            fieldGroupName
            heading
            subHeading
            cta {
              target
              title
              url
            }
          }
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_TwoColumnSection1 {
            description
            fieldGroupName
            heading
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            cta {
              target
              title
              url
            }
          }
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_GallerySection {
            description
            fieldGroupName
            heading
            imageGallery {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_AboutSection {
            description
            fieldGroupName
            heading
            subHeading
            stats {
              count
              fieldGroupName
              heading
            }
            cta {
              target
              title
              url
            }
          }
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_PricingSection {
            heading
            fieldGroupName
            subHeading
            price {
              fieldGroupName
              heading
              count {
                fieldGroupName
                heading
                price
              }
            }
          }
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_CtaSection {
            description
            fieldGroupName
            heading
            cta1 {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_GoogleMapSection {
            address
            fieldGroupName
            heading
            map
            subheading
          }
          ... on WpGoogleAdsPage_Googleadspostfieldgroups_GoogleAdsPostFields_GetInTouch {
            fieldGroupName
            formBtnLabel
            heading
            subheading
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }

    schemaLogo: file(relativePath: { eq: "logo.png" }) {
      publicURL
    }
  }
`;
