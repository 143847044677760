import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import Button from "react-bootstrap/Button";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { SafeHtmlParser } from "./safe-html-parser";
import ContactForm from "./contactForm";
import ContactFormAd from "./contact-form-ad";

const GetInTouch = ({
	heading,
	subHeading,
	backgroundImage,
	formButtonLabel,
}) => {
	const pluginImage = getImage(backgroundImage);

	const backgroundStyle = {
		backgroundColor: `#013B3799`,
		backgroundBlendMode: "multiply",
		zIndex: 0,
	};
	return (
		<BgImage
			id="form"
			Tag="section"
			className="py-6 py-md-9"
			image={pluginImage}
			alt="Hero Image"
		>
			<div
				className="position-absolute start-0 top-0 h-100 w-100"
				style={backgroundStyle}
			></div>
			<Container style={{ zIndex: 1 }} className="position-relative">
				<Row className="justify-content-center mb-5">
					<Col lg={8}>
						{subHeading && (
							<p className="text-uppercase text-center text-white pb-2 mb-0 ssp-semibold">
								{subHeading}
							</p>
						)}

						<h2 className="line-heading text-center text-white fs-1 merriweather-regular">
							{heading}
						</h2>
					</Col>
				</Row>

				<Row className="justify-content-center align-items-center h-100 get-in-touch">
					<Col lg={6} xl={5}>
						<div className="bg-white rounded-2 p-4">
							<ContactFormAd buttonText={formButtonLabel} isShowLabel={true} />
						</div>
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

export default GetInTouch;
