import React, { useState, useEffect } from "react";
import { useForm } from "@formspree/react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";

function ContactForm({page}) {
  const [state, handleSubmit] = useForm("xwpkvwqv");
  const [gclid, setGclid] = useState("");
  const [selectedButton, setSelectedButton] = useState("AM");
  const [activeStep, setActiveStep] = useState(1);
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
    numberOfPlayers: "",
    societyName: "",
    date: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleButtonClick = (value) => {
    setSelectedButton(value);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const gclidParam = urlParams.get("gclid");
    if (gclidParam) {
      setGclid(gclidParam);
    }
  }, []);

  const validateStep = () => {
    let errors = {};
    if (activeStep === 1) {
      if (!formValues.name) errors.name = "Name is required";
      if (!formValues.phone) errors.phone = "Phone is required";
      if (!formValues.email) errors.email = "Email is required";
    } else if (activeStep === 2) {
      if (!formValues.numberOfPlayers) errors.numberOfPlayers = "Number of players is required";
      if (!formValues.societyName) errors.societyName = "Society name is required";
    } else if (activeStep === 3) {
      if (!formValues.date) errors.date = "Date is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setActiveStep(activeStep + 1);
    }
  };

 const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!validateStep()) return;

    // Prepare all form values for submission
    const formData = new FormData();
    formData.append("name", formValues.name);
    formData.append("phone", formValues.phone);
    formData.append("email", formValues.email);
    formData.append("numberOfPlayers", formValues.numberOfPlayers);
    formData.append("societyName", formValues.societyName);
    formData.append("date", formValues.date);
    formData.append("gclid", gclid);
    formData.append("page", page);
    formData.append("preferred_tea_time", selectedButton);

    // Push data to the data layer for analytics
    if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
            event: "generate_lead",
            form_name: "ContactForm",
            customer_email: formValues.email,
            customer_phone_number: formValues.phone,
            // customer_name: formValues.name,
            // number_of_players: formValues.numberOfPlayers,
            // society_name: formValues.societyName,
            // date: formValues.date,
            // preferred_tea_time: selectedButton
        });
    }

    // Submit form data using handleSubmit from Formspree
    handleSubmit(formData);
};


  if (state.succeeded) {
    window.location.href = "/thank-you";
    return null;
  }

  return (
    <Form className="w-100" onSubmit={handleFormSubmit}>
      {activeStep === 1 && (
        <>
          <Form.Group className="mb-4" controlId="name">
            <Form.Label className="text-white">Name *</Form.Label>
            <Form.Control
              className="py-1 fs-5 text-white white-input"
              name="name"
              placeholder="Enter your name"
              type="text"
              value={formValues.name}
              onChange={handleInputChange}
              required
            />
            {formErrors.name && <span className="p-error">{formErrors.name}</span>}
          </Form.Group>

          <Form.Group className="mb-4" controlId="phone">
            <Form.Label className="text-white">Phone *</Form.Label>
            <Form.Control
              className="py-1 fs-5 text-white white-input"
              name="phone"
              placeholder="Enter your phone"
              type="tel"
              value={formValues.phone}
              onChange={handleInputChange}
              required
            />
            {formErrors.phone && <span className="p-error">{formErrors.phone}</span>}
          </Form.Group>

          <Form.Group className="mb-4" controlId="email">
            <Form.Label className="text-white">Email *</Form.Label>
            <Form.Control
              className="py-1 fs-5 text-white white-input"
              name="email"
              type="email"
              placeholder="Enter your email"
              value={formValues.email}
              onChange={handleInputChange}
              required
            />
            {formErrors.email && <span className="p-error">{formErrors.email}</span>}
          </Form.Group>

          <div className="text-center">
            <Button
              variant="white"
              className="px-5 mt-4 text-uppercase me-md-3 py-3 w-100"
              onClick={handleNextStep}
            >
              Next
            </Button>
          </div>
        </>
      )}

      {activeStep === 2 && (
        <>
          <Form.Group className="mb-4" controlId="numberOfPlayers">
            <Form.Label className="text-white">Number of players *</Form.Label>
            <Form.Control
              className="py-1 fs-5 text-white white-input"
              name="numberOfPlayers"
              type="text"
              placeholder="Enter number of players"
              value={formValues.numberOfPlayers}
              onChange={handleInputChange}
              required
            />
            {formErrors.numberOfPlayers && <span className="p-error">{formErrors.numberOfPlayers}</span>}
          </Form.Group>

          <Form.Group className="mb-4" controlId="societyName">
            <Form.Label className="text-white">Society name *</Form.Label>
            <Form.Control
              className="py-1 fs-5 text-white white-input"
              name="societyName"
              type="text"
              placeholder="Enter society name"
              value={formValues.societyName}
              onChange={handleInputChange}
              required
            />
            {formErrors.societyName && <span className="p-error">{formErrors.societyName}</span>}
          </Form.Group>

          <div className="text-center">
            <Button
              variant="white"
              className="px-5 mt-4 text-uppercase me-md-3 py-3 w-100"
              onClick={handleNextStep}
            >
              Next
            </Button>
          </div>
        </>
      )}

      {activeStep === 3 && (
        <>
          <Form.Group className="mb-4" controlId="date">
            <Form.Label className="text-white">Date *</Form.Label>
            <Form.Control
              className="py-1 fs-5 white-input"
              name="date"
              type="text"
              placeholder="Enter date"
              value={formValues.date}
              onChange={handleInputChange}
              required
            />
            {formErrors.date && <span className="p-error">{formErrors.date}</span>}
          </Form.Group>

          <div className="d-flex align-items-center justify-content-between">
            <label htmlFor="time" className="fs-6 text-white">
              Preferred tee time
            </label>
            <ToggleButtonGroup
              id="time"
              type="radio"
              name="time"
              value={selectedButton}
              className="bg-white p-1 border-0 rounded-0"
            >
              <ToggleButton
                variant="outline-primary"
                value="AM"
                onClick={() => handleButtonClick("AM")}
                className="py-2 px-4 border-0 rounded-0"
              >
                AM
              </ToggleButton>
              <ToggleButton
                variant="outline-primary"
                value="PM"
                onClick={() => handleButtonClick("PM")}
                className="py-2 px-4 border-0 rounded-0"
              >
                PM
              </ToggleButton>
            </ToggleButtonGroup>
            {formErrors.time && <span className="p-error">{formErrors.time}</span>}
          </div>

          <div className="text-center">
            <Button
              variant="white"
              className="px-5 mt-4 text-uppercase me-md-3 py-3 w-100"
              type="submit"
              disabled={state.submitting}
            >
              Submit
            </Button>
          </div>
        </>
      )}
      <Form.Group controlId="gclid" className="d-none">
				<Form.Control type="hidden" name="gclid" value={gclid} />
			</Form.Group>
       <Form.Group controlId="page" className="d-none">
				<Form.Control type="hidden" name="page" value={page} />
			</Form.Group>
    </Form>
  );
}

export default ContactForm;
