import React from "react";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import Footer from "./googleAdsFooter";
import Navigation from "./googleAdsNavigation";
import { Helmet } from "react-helmet";

const Layout = ({ children }) => {
	return (
		<>
			{/* <Helmet>
        {typeof Window !== "undefined" && (
          <script
            src="https://formspree.io/js/formbutton-v1.min.js"
            defer
          ></script>
        )}
        {typeof Window !== "undefined" && (
          <script
            type="text/javascript"
            innerHTML={`                   
               window.formbutton=window.formbutton||function(){(formbutton.q=formbutton.q||[]).push(arguments)};
    formbutton("create", {action: "https://formspree.io/f/xgerzagz"})`}
          />
        )}
      </Helmet> */}
			<Navigation />
			<CookieBanner disableUrl />
			{children}
			<Footer />
		</>
	);
};

export default Layout;
