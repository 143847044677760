import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import Button from "react-bootstrap/Button";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { SafeHtmlParser } from "./safe-html-parser";

const CtaBanner = ({
  heading,
  description,
  backgroundImage,
  button1Text,
  button1Link,
}) => {
  const pluginImage = getImage(backgroundImage);

  const backgroundStyle = {
    // backgroundColor: `rgba(1, 59, 55, 0.2)`,
    backgroundBlendMode: "multiply",
    zIndex: 0,
  };
  return (
    <BgImage
      Tag="section"
      className="py-5 py-md-7 my-4 my-sm-6"
      image={pluginImage}
      alt="Hero Image"
    >
      <div
        className="position-absolute start-0 top-0 h-100 w-100"
        style={backgroundStyle}
      ></div>
      <Container style={{ zIndex: 1 }} className="position-relative">
        <Row className=" px-3 justify-content-center">
          <Col xs={12} lg={9} className="pl-0 ps-md-3 text-center">
            <h1 className="pt-0 text-white merriweather-regular" id="services-hero-title">
              {heading}
            </h1>

            {description && (
              <div className="text-white mt-3">
                <SafeHtmlParser htmlContent={description} />
              </div>
            )}

            <Row className="mt-4">
              <Col>
                {button1Text !== null && (
                  <Button
                    variant="white"
                    href={button1Link}
                    className="py-3 w-100 w-md-auto mb-3 mb-lg-0 mb-md-0 px-5"
                  >
                    {button1Text}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </BgImage>
  );
};

export default CtaBanner;
