import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";

function ContactFormAd({ buttonText, isShowLabel }) {
	const [state, handleSubmit] = useForm("xwpkvwqv");
	const [gclid, setGclid] = useState("");
	const [selectedButton, setSelectedButton] = useState("AM");
	const [formValues, setFormValues] = useState({
		name: "",
		phone: "",
		email: "",
		numberOfPlayers: "",
		societyName: "",
		date: "",
	});
	const handleButtonClick = (value) => {
		setSelectedButton(value);
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const gclidParam = urlParams.get("gclid");
		if (gclidParam) {
			setGclid(gclidParam);
		}
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();

		if (typeof window !== "undefined" && window.dataLayer) {
			window.dataLayer.push({
				event: "generate_lead",
				form_name: "ContactForm",
				customer_email: formValues.email,
				customer_phone_number: formValues.phone,
				// customer_name: formValues.name,
				// number_of_players: formValues.numberOfPlayers,
				// society_name: formValues.societyName,
				// date: formValues.date,
				// preferred_tea_time: selectedButton,
			});
		}

		handleSubmit(event);
	};

	if (state.succeeded) {
		window.location.href = "/thank-you";
		return null;
	}

	return (
		<Form className="w-100" onSubmit={handleFormSubmit}>
			<Form.Group className="mb-3" controlId="name">
				{isShowLabel && <Form.Label>Name *</Form.Label>}
				<Form.Control
					name="name"
					placeholder="Name"
					type="text"
					value={formValues.name}
					onChange={handleInputChange}
					required
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />

			<Form.Group className="mb-3" controlId="phone">
				{isShowLabel && <Form.Label>Phone *</Form.Label>}
				<Form.Control
					name="phone"
					placeholder="Phone"
					type="tel"
					value={formValues.phone}
					onChange={handleInputChange}
					required
				/>
			</Form.Group>
			<ValidationError prefix="Phone" field="phone" errors={state.errors} />

			<Form.Group className="mb-3" controlId="email">
				{isShowLabel && <Form.Label>Email *</Form.Label>}
				<Form.Control
					name="email"
					type="email"
					placeholder="Email"
					value={formValues.email}
					onChange={handleInputChange}
					required
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mb-3" controlId="numberOfPlayers">
				{isShowLabel && <Form.Label>Number of Players *</Form.Label>}
				<Form.Control
					name="numberOfPlayers"
					placeholder="Enter number of players"
					type="text"
					value={formValues.numberOfPlayers}
					onChange={handleInputChange}
					required
				/>
			</Form.Group>

			<Form.Group className="mb-3" controlId="societyName">
				{isShowLabel && <Form.Label>Society Name *</Form.Label>}
				<Form.Control
					name="societyName"
					placeholder="Enter society name"
					type="text"
					value={formValues.societyName}
					onChange={handleInputChange}
					required
				/>
			</Form.Group>

			<Form.Group className="mb-3" controlId="date">
				{isShowLabel && <Form.Label>Date *</Form.Label>}
				<Form.Control
					name="date"
					type="text"
					placeholder="Enter date"
					value={formValues.date}
					onChange={handleInputChange}
					required
				/>
			</Form.Group>
			<div className="d-flex align-items-center justify-content-between">
				<label htmlFor="time" className="fs-6 ">
					Preferred tee time
				</label>
				<ToggleButtonGroup
					id="time"
					type="radio"
					name="time"
					value={selectedButton}
					className="bg-primary p-1 border-0 rounded-0"
				>
					<ToggleButton
						variant="primary"
						value="AM"
						onClick={() => handleButtonClick("AM")}
						className="py-2 px-4  border-0 rounded-0"
					>
						AM
					</ToggleButton>
					<ToggleButton
						variant="primary"
						value="PM"
						onClick={() => handleButtonClick("PM")}
						className="py-2 px-4 border-0 rounded-0"
					>
						PM
					</ToggleButton>
				</ToggleButtonGroup>
			</div>

			<Form.Group controlId="gclid" className="d-none">
				<Form.Control type="hidden" name="gclid" value={gclid} />
			</Form.Group>

			<div className="text-center">
				<Button
					variant="primary"
					className="px-5 mt-4 text-uppercase text-white me-md-3 py-3 w-100"
					type="submit"
					disabled={state.submitting}
				>
					{buttonText ?? "Submit"}
				</Button>
			</div>
		</Form>
	);
}

export default ContactFormAd;
