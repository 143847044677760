import React, { useState } from "react";
import { Link } from "gatsby";
import { Row, Col, Button, Container } from "react-bootstrap";
import StarLogo from "../images/icons/star.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const starCount = 5;

function Review({ review, minHeight }) {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const shortenText = (text) => {
    if (text.length > 140) {
      const lastSpaceIndex = text.lastIndexOf(" ", 140);
      return `${text.substring(0, lastSpaceIndex)}...`;
    }
    return text;
  };

  return (
    <Col xl={12}>
      <div
        style={{ minHeight: minHeight }}
        className="mx-3 ms-lg-0 me-lg-4 bg-dark-green p-4 py-sm-5 px-sm-6 text-center position-relative rounded-2"
      >
        <h3 className="text-uppercase text-white fs-5 pb-3">{review.author}</h3>
        {review.reviewBody.length > 140 && (
          <p className="text-white pb-4">
            {showFullText ? review.reviewBody : shortenText(review.reviewBody)}
            {review.reviewBody.length > 140 && (
              <a
                className="white-link"
                style={{ cursor: "pointer" }}
                onClick={toggleText}
              >
                <br /> {showFullText ? " Read less" : " Read more"}
              </a>
            )}
          </p>
        )}
        {review.reviewBody.length <= 140 && (
          <p className="text-white pb-4">{review.reviewBody}</p>
        )}

        <div className="d-flex w-100 position-absolute  start-0 bottom-0 pb-4 pb-sm-6 justify-content-center mt-2">
          {Array.from(Array(starCount), (_, index) => (
            <img
              key={index}
              src={StarLogo}
              alt="Star"
              className={`d-inline-block ${
                index !== starCount - 1 ? "me-2" : ""
              }`}
              style={{
                maxWidth: "20px",
              }}
            />
          ))}
        </div>
      </div>
    </Col>
  );
}

const ReviewsSlider = ({ reviews, subHeading, heading, cta }) => {
  return (
    <>
      <section className="py-4 py-md-5 py-lg-7 bg-primary review-section">
        <Container>
          <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
            <Col lg={8}>
              {subHeading && (
                <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                  {subHeading}
                </p>
              )}

              <h2 className="line-heading text-center text-white mb-5 fs-1 merriweather-regular">
                {heading}
              </h2>
            </Col>
          </Row>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              },
            }}
            infinite={true}
            arrows={true}
            className="pt-md-4 pb-7"
          >
            {reviews.map((review, i) => (
              <Row className="justify-content-center">
                <Review minHeight="350px" review={review} />
              </Row>
            ))}
          </Carousel>
          {cta && cta?.url && (
            <Col>
              <div className="text-center">
                <Button
                  style={{ borderRadius: "0px" }}
                  variant="outline-white"
                  as={Link}
                  target={cta?.target ?? "_blank"}
                  rel="noreferrer"
                  href={cta?.url}
                  className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-5 px-6 "
                >
                  {cta?.title}
                </Button>
              </div>
            </Col>
          )}
        </Container>
      </section>
    </>
  );
};

export default ReviewsSlider;
